import React from 'react';
import PrintIcon from '@mui/icons-material/Print';
import IconButton from '@mui/material/IconButton';

const PrintComponent = ({ data1, data2 }) => {
  const handlePrint = () => {
    window._satellite.track("cta-print", { name: "Print",location: "Header" });
    const printWindow = window.open('', '', 'width=800,height=600');
    printWindow.document.write('<html><head><title>Print</title>');
    printWindow.document.write('<style>@media print { body { font-family: Arial, sans-serif; } table { width: 100%; border-collapse: collapse; } th, td { border: 1px solid black; padding: 8px; text-align: left; } }</style>');
    printWindow.document.write('</head><body>');
    printWindow.document.write('<h1>REMS Data</h1>');
    printWindow.document.write(renderTable(header1, data1));
    printWindow.document.write('<br><br><br><br><br><br>'); // Add three lines of gap here
    printWindow.document.write('<h1>MyCAMZYOS Program Data</h1>');
    printWindow.document.write(renderTable(header2, data2));
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
  };

  const renderTable = (header, data) => {
    let table = '<table><thead><tr>';
    header.forEach(key => {
      table += `<th>${key}</th>`;
    });
    table += '</tr></thead><tbody>';
    data.forEach(item => {
      table += '<tr>';
      header.forEach(key => {
        table += `<td>${item[key] || ''}</td>`;
      });
      table += '</tr>';
    });
    table += '</tbody></table>';
    return table;
  };

  const header1 = [
    "Patient Name",
    "DOB",
    "Patient REMS Status",
    "Last Shipment Date SP Name",
    "SP Number",
    "Next PSF eligible date to submit",
    "Task(s)",
    "Tasks Description",
  ];

  const header2 = [
    "Patient Name",
    "DOB",
    "MyCAMZYOS Status",
    "PA Status",
    "PA Status as of",
    "Type of Dispense",
    "Task(s)",
    "Tasks Description",
  ];

  return (
    <div><IconButton onClick={handlePrint} aria-label="print"><PrintIcon /></IconButton></div>
  );
};

export default PrintComponent;