import { useState, useMemo, useEffect } from "react";
import { formatDate } from "../utils";
import { patientTableData } from "../utils";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  styled,
  IconButton,
  Select,
  MenuItem,
  Pagination,
  PaginationItem,
  TableSortLabel,
  createTheme,
  ThemeProvider,
  FormControl,
  Snackbar,
  Grid,
  Switch,
  FormControlLabel,
} from "@mui/material";
import TableTooltip from "./TableTooltip";
import Circle from "../components/Circle";
import InfoIcon from "@mui/icons-material/Info";
import { ReactComponent as Phone_Icon } from "../assets/images/phone_icon.svg";
import Status from "../components/Status";
import { ReactComponent as PartiallyEnrolled } from "../assets/images/partially_enrolled_new.svg";
import SearchBar from "../components/Searchbar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ReactComponent as Copy_Icon } from "../assets/images/copy.svg";
import Loader from "./Loader";
import moment from "moment";
import { StatusArrayForExcel } from "../utils";
import PrintComponent from "../components/PrintComponent";

const StickyTableHead = styled(TableHead)({
  position: "sticky",
  top: 0,
  zIndex: 1,
  backgroundColor: "#fff",
});
const ScrollableTableContainer = styled(TableContainer)({
  //maxHeight: '500px', // Set the desired height for the scrollable area
  overflowY: "scroll",
  //   '&::-webkit-scrollbar': {
  //     display:'none'
  //   },
});

const FixedTableCell = styled(TableCell)(({ theme }) => ({
  position: "sticky",
  left: 0,
  zIndex: theme.zIndex.appBar + 2, // Ensure the cell is above other elements
  //zIndex: theme.zIndex + 2,
  borderRight: "2px solid #E2E6EA",
  backgroundColor: "#414141",
  minWidth: 100,
  //padding: '5px 8px',
  color: "#1976d2",
  align: "left",
}));
const FixedTableCellForSearchField = styled(TableCell)(({ theme }) => ({
  position: "sticky",
  left: 0,
  zIndex: theme.zIndex.appBar + 2, // Ensure the cell is above other elements
  //zIndex: theme.zIndex + 2,
  backgroundColor: "#FFF",
  minWidth: 100,
  //padding: '5px 8px',
  color: "#FFF",
  align: "left",
  borderRight: "1px solid #A0A0A0",
  borderLeft: "1px solid #A0A0A0",
  borderTop: "1px solid #A0A0A0",
  borderTopLeftRadius: "5px",
  borderTopRightRadius: "5px",
}));
const FixedTableBodyCell = styled(TableCell)(({ theme }) => ({
  position: "sticky",
  left: 0,
  //zIndex: theme.zIndex.appBar + 2, // Ensure the cell is above other elements
  zIndex: theme.zIndex + 2,
  backgroundColor: "#FFFBF2",
  borderRight: "2px solid #FFFBF2",
  borderBottom: "2px solid #97999A",
  borderLeft: "2px solid #97999A",
  boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
  align: "left",
}));
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme, toggledcolor, offcolor }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor:
          toggledcolor ||
          (theme.palette.mode === "dark" ? "#2ECA45" : "#65C466"),
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor:
      offcolor || (theme.palette.mode === "light" ? "#E9E9EA" : "#39393D"),
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
const currentDate = new Date();

const options = {
  month: "numeric",
  day: "numeric",
  year: "numeric",
  hour: "numeric",
  minute: "numeric",
  hour12: true,
  timeZone: "America/New_York",
  timeZoneName: "short",
};

const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "+1 ";
    return [intlCode, match[2], "-", match[3], "-", match[4]].join("");
  }
  return null;
};

const PatientTable = (props) => {
  const {
    onNumberClick,
    patientList,
    tableHeadCounts,
    getPatientListData,
    showPatientLoader,
  } = props;
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({
    key: "patient_hub_tasks",
    direction: "desc",
  });
  const [inputValue, setInputValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [hcpList, setHcpList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [selected_HCP_npi_number, setSelected_HCP_npi_number] = useState("");
  const [hideDropDown, sethideDropDown] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const [currentUserRole, setCurrentUserRole] = useState("");
  const [isToggled, setIsToggled] = useState(false);
  useEffect(() => {
    if (isToggled) {
      // Logic when switch is turned on
      // Add your logic here for when the toggle is ON
      const filteredDataaa = filterByKeys();

      console.log(filteredDataaa);
      setFilteredData(filteredDataaa);
    } else {
      setFilteredData(patientList);
    }
  }, [patientList]);
  const prepareDataForExport = (rows, type) => {
    return rows.map((row) => {
      let tasksDescription = "";
      let remsTasksDescription = ``;
      if (row.rems_tasks.tasks == 1) {
        const tasksDescrpn = StatusArrayForExcel.filter(
          (item) =>
            item.title?.toLowerCase() == row.rems_status.status?.toLowerCase()
        ).map((item) => {
          const title = item.isTitleNeeded ? item.title : "";
          const message = item.message;
          remsTasksDescription = `${title}\n${message}`;
          return tasksDescription;
        });
      }
      let combinedTasksDescription = ``;
      if (row.phub_tasks.tasks > 0) {
        const hubTaskDesc = StatusArrayForExcel.filter(
          (item) =>
            item.title?.toLowerCase() ==
            row.hub_status.camzyos_status_modal?.toLowerCase()
        )
          .concat(
            StatusArrayForExcel.filter(
              (item) =>
                item.title?.toLowerCase() ==
                row.hub_status.pa_status_modal?.toLowerCase()
            )
          )
          .map((item) => {
            const title = item.isTitleNeeded ? item.title : "";
            const message = item.message;
            combinedTasksDescription = `${combinedTasksDescription}\n${title}\n${message}`;
            return combinedTasksDescription;
          });
      }

      if (type === "Print1") {
        return {
          "Patient Name": row.patient.first_name,
          DOB: formatDate(row.date_of_birth),
          "Patient REMS Status": row.rems_status.status,
          "Last Shipment Date SP Name":
            row.psf_status?.last !== ""
              ? formatDate(row.psf_status?.last) + "  " + row.psf_status.spname
              : "",
          "SP Number": formatPhoneNumber(row.psf_status.number),
          "Next PSF eligible date to submit": row.psf_status.nextTo,
          "Task(s)": row.rems_tasks.tasks === 1 ? row.rems_tasks.tasks : "--",
          "Tasks Description":
            row.rems_tasks.tasks === 1 ? remsTasksDescription : "",
        };
      } else if (type === "Print2") {
        return {
          "Patient Name": row.patient.first_name,
          DOB: formatDate(row.date_of_birth),
          "MyCAMZYOS Status": row.hub_status.camzyos_status,
          "PA Status": row.hub_status.access_status,
          "PA Status as of": formatDate(row.hub_status?.date),
          "Type of Dispense": row.dispense.type_of_dispense,
          "Task(s)": row.phub_tasks.tasks > 0 ? row.phub_tasks.tasks : "--",
          "Tasks Description":
            row.phub_tasks.tasks > 0 ? combinedTasksDescription : "",
        };
      } else {
        return null;
      }
    });
  };

  const data1 = prepareDataForExport(patientList, "Print1");
  const data2 = prepareDataForExport(patientList, "Print2");
  useEffect(() => {
    let hcpListData = props.hcpList;
    let user_data = localStorage.getItem("user_data");
    //let user_id = "0057d000005wMlZAAU";
    let user_id = "";

    if (user_data) {
      user_id = user_data["custom:user_id"];
    }
    if (hcpListData && hcpListData.data && hcpListData.data.loggedinUserRole) {
      let loggedinUserRole = hcpListData.data.loggedinUserRole;
      setCurrentUserRole(loggedinUserRole);
      if (loggedinUserRole === "Healthcare Provider") {
        sethideDropDown(true);
        if (hcpListData && hcpListData.data && hcpListData.data.npi_number) {
          const npi_number = hcpListData.data.npi_number;
          setHcpList([]);
          setSelectedValue("");
          setSelected_HCP_npi_number(npi_number);
        }
      } else if (
        loggedinUserRole === "Designee" ||
        loggedinUserRole === "Support Staff"
      ) {
        setFilteredData(patientList);
        if (hcpListData && hcpListData.data && hcpListData.data.resRows) {
          const hcpList = hcpListData.data.resRows;
          sethideDropDown(false);
          setHcpList(hcpList);
          setSelectedValue(hcpList[0].name);
          setSelected_HCP_npi_number(hcpList[0].npi_number);
        }
      } else if (
        loggedinUserRole === "Healthcare Provider Designee" ||
        loggedinUserRole === "Healthcare Provider, Designee" ||
        loggedinUserRole === '"Healthcare Provider'
      ) {
        if (hcpListData && hcpListData.data && hcpListData.data.resRows) {
          const hcpList = hcpListData.data.resRows;
          const npi_number = hcpListData.data.npi_number;
          sethideDropDown(false);
          setHcpList(hcpList);
          const foundHcpItem = hcpList.find(
            (item) => item.npi_number === npi_number
          );
          if (foundHcpItem) {
            setSelectedValue(foundHcpItem.name);

            setSelected_HCP_npi_number(foundHcpItem.npi_number);
          } else {
            setSelectedValue(hcpList[0].name);
            setSelected_HCP_npi_number(hcpList[0].npi_number);
          }
        }
      }
    }
  }, []);

  const handleHCPListDropDownChange = (event) => {
    setInputValue("");
    setSelectedValue(event.target.value);
    const selectedOption = hcpList.find(
      (option) => option.name === event.target.value
    );
    setSelected_HCP_npi_number(selectedOption.npi_number);
    getPatientListData(selectedOption.npi_number);
  };

  const handleinputchange = (event) => {
    setInputValue(event.target.value);
    if (
      event.target.value === "" ||
      event.target.value === undefined ||
      event.target.value === null ||
      event.target.value.trim().length == 0
    ) {
      if (isToggled) {
        setFilteredData(filteredData);
      } else {
        setFilteredData(patientList);
      }
    } else {
      if (isToggled) {
        const filteredDataa = filterByKeys();
        const filtered = filterByName(filteredDataa, event.target.value);
        setPage(1);
        setFilteredData(filtered);
      } else {
        const filtered = filterByName(patientList, event.target.value);
        setPage(1);
        setFilteredData(filtered);
      }
    }
  };
  const handleTogglee = (event) => {
    const checked = event.target.checked;
    setIsToggled(checked);
    if (checked) {
      // Logic when switch is turned on
      // Add your logic here for when the toggle is ON
      const filteredData = filterByKeys();

      console.log(filteredData);
      if (
        inputValue === "" ||
        inputValue === undefined ||
        inputValue === null ||
        inputValue.trim().length == 0
      ) {
        setFilteredData(filteredData);
      } else {
        const filtered = filterByName(filteredData, inputValue);
        setPage(1);
        setFilteredData(filtered);
      }
    } else {
      // Logic when switch is turned off
      if (
        inputValue === "" ||
        inputValue === undefined ||
        inputValue === null ||
        inputValue.trim().length == 0
      ) {
        setFilteredData(patientList);
      } else {
        const filtered = filterByName(patientList, inputValue);
        setPage(1);
        setFilteredData(filtered);
      }
    }
  };
  const filterByName = (Listdata, SearchName) => {
    return Listdata.filter(
      (item) =>
        item.patient.first_name
          ?.toLowerCase()
          .includes(SearchName.trim()?.toLowerCase()) ||
        item.patient.last_name
          ?.toLowerCase()
          .includes(SearchName.trim()?.toLowerCase())
    );
  };

  const filterByKeys = () => {
    return patientList.filter((item) => {
      const keys = [
        item.hub_status?.camzyos_status,
        item.hub_status?.access_status,
        item.dispense?.type_of_dispense,
        item.hub_status?.date,
        item.phub_tasks?.tasks,
      ];

      const allKeysEmpty = keys.every(
        (key) => key === null || key === undefined || key === "" || key === 0
      );
      const someKeysNotEmpty = keys.some(
        (key) => key !== null && key !== undefined && key !== "" && key !== 0
      );

      return someKeysNotEmpty && !allKeysEmpty;
    });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  // Avoid a layout jump when reaching the last page with empty patientTableData.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredData.length) : 0;

  const stableSort1 = (array) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };
  const stableSort = (array) => {
    return array.sort((a, b) => {
      if (sortConfig.key !== null) {
        let nameA = a.rems_status.status?.toLowerCase();
        let nameB = b.rems_status.status?.toLowerCase();
        if (sortConfig.key === "patient_name_dob") {
          nameA = a.patient.first_name?.toLowerCase();
          nameB = b.patient.first_name?.toLowerCase();
        } else if (sortConfig.key === "patient_rems_status") {
          nameA = a.rems_status.status?.toLowerCase();
          nameB = b.rems_status.status?.toLowerCase();
        } else if (sortConfig.key === "patient_tasks") {
          nameA = a.rems_tasks.tasks;
          nameB = b.rems_tasks.tasks;
        } else if (sortConfig.key === "camzyos_status") {
          nameA = a.hub_status.camzyos_status;
          nameB = b.hub_status.camzyos_status;
        } else if (sortConfig.key === "access_status") {
          nameA = a.hub_status.access_status;
          nameB = b.hub_status.access_status;
        } else if (sortConfig.key === "type_of_dispense") {
          nameA = a.dispense.type_of_dispense;
          nameB = b.dispense.type_of_dispense;
        } else if (sortConfig.key === "patient_hub_tasks") {
          nameA = a.phub_tasks.tasks;
          nameB = b.phub_tasks.tasks;
        } else if (sortConfig.key === "last_shipment_date_sp_name") {
          nameA = a.psf_status.last != "" ? new Date(a.psf_status.last) : "";
          nameB = b.psf_status.last != "" ? new Date(b.psf_status.last) : "";
        } else if (sortConfig.key === "next_psf_eligible_date") {
          nameA = a.psf_status.next != "" ? new Date(a.psf_status.next) : "";
          nameB = b.psf_status.next != "" ? new Date(b.psf_status.next) : "";
        } else if (sortConfig.key === "auth_status_date") {
          nameA = a.hub_status.date != "" ? new Date(a.hub_status.date) : "";
          nameB = b.hub_status.date != "" ? new Date(b.hub_status.date) : "";
        }
        if (nameA != "" ? nameA < nameB : nameB != "" ? nameB : false) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (nameA != "" ? nameA > nameB : nameB != "" ? nameB : false) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }

        return 0;
      }
      return 0;
    });
    //return stabilizedThis.map((el) => el[0]);
  };
  //const startIndex = (page - 1) * rowsPerPage;
  //const endIndex = startIndex + rowsPerPage;
  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = Math.min(startIndex + rowsPerPage, filteredData.length);
  const visibleRows = useMemo(() => {
    return stableSort([...filteredData]).slice(startIndex, endIndex);
  }, [page, rowsPerPage, selectedValue, filteredData, patientList, sortConfig]);

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const chartsParams = {
    margin: { top: 20, bottom: 20, left: 15, right: 5 },
    height: 70,
    width: 120,
  };
  const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "& fieldset": {
              borderColor: "red", // Change this to your desired color
            },
            "&:hover fieldset": {
              borderColor: "red", // Change this to your desired color
            },
            "&.Mui-focused fieldset": {
              borderColor: "red", // Change this to your desired color
            },
          },
        },
      },
    },
  });

  if (
    props.apiErrorMessage !== undefined &&
    props.apiErrorMessage &&
    props.apiErrorMessage !== ""
  ) {
    return (
      <div
        style={{
          height: "43vh",
          alignContent: "center",
          justifyItems: "center",
          alignSelf: "center",
        }}
      >
        <Typography
          sx={{
            alignSelf: "center",
            fontSize: 18,
            color: "#595454",
            lineHeight: "normal",
            fontFamily: "BMSHumanity",
            fontWeight: 700,
          }}
        >
          {props.apiErrorMessage}
        </Typography>
      </div>
    );
  }
  if (props.hcpList && props.hcpList.length < 1) {
    return (
      <div
        style={{
          height: "43vh",
          alignContent: "center",
          justifyItems: "center",
          alignSelf: "center",
        }}
      >
        <Typography
          sx={{
            alignSelf: "center",
            fontSize: 18,
            color: "#595454",
            lineHeight: "normal",
            fontFamily: "BMSHumanity",
            fontWeight: 700,
          }}
        >
          There are no HCPs associated with your account.
        </Typography>
      </div>
    );
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={6} sm={6}>
            <div
              style={{
                alignSelf: "flex-start",
                display: "flex",
                flexDirection: "row",
              }}
            >
              {!hideDropDown ? (
                <>
                  <Typography
                    id="hcp-list-label"
                    sx={{
                      alignContent: "center",
                      fontSize: 18,
                      color: "#595454",
                      lineHeight: "normal",
                      fontFamily: "BMSHumanity",
                      fontWeight: 700,
                      paddingLeft: "11px",
                    }}
                  >
                    Select HCP to View Patients
                  </Typography>
                  <ThemeProvider theme={theme}>
                    <FormControl
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": { borderColor: "#949494" },
                        },
                      }}
                    >
                      <Select
                        //className="custom-select"
                        labelId="hcp-list-label"
                        IconComponent={ArrowDropDownIcon}
                        //value={'Dr. John Smith'}
                        value={selectedValue}
                        onChange={handleHCPListDropDownChange}
                        sx={{
                          width: "253px",
                          height: "41px",
                          marginLeft: "10px",
                          borderRadius: "1px",
                          borderWidth: "0px",
                          borderColor: "#949494",
                          backgroundColor: "#FFF",
                          fontFamily: "BMSHumanity",
                          fontSize: 16,
                          "&:focus": {
                            backgroundColor: "#949494",
                          },
                          "& .MuiSelect-icon": {
                            color: "#21387A", // Replace with your desired color
                          },
                        }}
                        aria-label="Select HCP to View Patients"
                        aria-live="polite"
                      >
                        {hcpList.map((option, index) => (
                          <MenuItem
                            key={index}
                            value={option.name}
                            sx={{
                              fontFamily: "BMSHumanity",
                              fontSize: 16,
                              color: "#000",
                            }}
                          >
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </ThemeProvider>
                </>
              ) : null}
            </div>
          </Grid>
          <Grid item xs={6} sm={6}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                justifyContent: "flex-end",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "36px",
                  alignSelf: "center",
                }}
              >
                {!showPatientLoader ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box sx={{ margin: "1px" }}>
                      <PrintComponent data1={data1} data2={data2} />
                    </Box>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "4px",
                      width: "117px",
                      height: "36px",
                      "&:hover": {
                        backgroundColor: "#f0f0f0",
                      },
                    }}
                  ></Box>
                )}
              </div>
              <Box
                sx={{
                  alignSelf: "center",
                  width: "2px", // Width of the vertical line
                  height: "45px", // Height of the vertical line
                  backgroundColor: "#D0D0D0", // Color of the vertical line
                  margin: "0 8px", // Optional: Add some margin for spacing
                  border: "1px",
                  marginLeft: "25px",
                  marginRight: "25px",
                }}
              />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        checked={isToggled}
                        onChange={handleTogglee}
                        inputProps={{ "aria-label": "controlled" }}
                        toggledcolor={"#BE2BBB"}
                        offcolor={"#BEBEBE"}
                      />
                    }
                    label={"Show MyCAMZYOS Consented Patients"}
                    sx={{
                      marginRight: "0px",
                      marginLeft: "0px",
                      "& .MuiFormControlLabel-label": {
                        color: "#595454",
                        fontFamily: "BMSHumanity",
                        fontSize: {
                          md: "18px",
                          lg: "18px",
                          sm: "14px",
                          xs: "14px",
                        },
                        fontWeight: 700,
                        lineHeight: "22px",
                        textAlign: "right",
                        marginLeft: "5px",
                      },
                    }}
                  />
                </div>
                {!showPatientLoader &&
                patientList &&
                patientList.length > 0 &&
                moment(tableHeadCounts?.lastRefreshDate).isValid() ? (
                  <div>
                    <Typography
                      sx={{
                        fontFamily: "BMSHumanity",
                        fontSize: {
                          md: "14px",
                          lg: "14px",
                          sm: "10px",
                          xs: "10px",
                        },
                        fontStyle: "italic",
                        fontWeight: 400,
                        lineHeight: "24px",
                        textAlign: "right",
                        color: "#595454",
                      }}
                    >
                      {" "}
                      Data refreshed as of{" "}
                      {moment(tableHeadCounts?.lastRefreshDate).format(
                        "MM/DD/YYYY H:mm A"
                      )}{" "}
                      ET
                      {/* {new Date().getDate()}/{new Date().getMonth()}/{new Date().getFullYear()} {new Date().getHours()}{new Date().getHours() >= 12 ? 'pm' : 'am'} EST. */}
                    </Typography>
                  </div>
                ) : null}
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
      {!showPatientLoader ? (
        patientList && patientList.length > 0 ? (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                backgroundColor: "#fff",
                boxShadow:
                  "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
                marginTop: "20px",
              }}
            >
              <Paper
                elevation={0}
                sx={{ borderRadius: 0, width: "100%", overflow: "hidden" }}
              >
                <div>
                  <div style={{ overflow: "auto" }}>
                    <ScrollableTableContainer>
                      <Table
                        stickyHeader
                        sx={{
                          minWidth: 650,
                          borderBottom: "1px solid #A0A0A0",
                        }}
                        aria-labelledby="sticky table"
                        size={"small"}
                      >
                        <StickyTableHead>
                          <TableRow>
                            <FixedTableCellForSearchField
                              align={"center"}
                              // sx={{ minWidth: 150, padding: '10px 16px', color: '#FFF', backgroundColor: '#FFF', borderRight: '1px solid #A0A0A0', borderLeft:'1px solid #A0A0A0', borderTop:'1px solid #A0A0A0',borderTopLeftRadius:'5px', borderTopRightRadius: '5px', position: 'sticky',
                              // left: 0,
                              // zIndex: 1,}}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  width: "100%",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                }}
                              >
                                {/* <Box sx={{ display: 'flex', flexDirection:'column' }}>   */}
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    lineHeight: "normal",
                                    fontFamily: "BMSHumanity",
                                    fontWeight: 400,
                                    fontSize: 16,
                                    color: "#595454",
                                    padding: "5px 0px 0px 0px",
                                  }}
                                >
                                  {"Search patient"}
                                </Typography>
                                <SearchBar
                                  inputValue={inputValue}
                                  handleinputchange={handleinputchange}
                                  filteredData={filteredData}
                                />
                                {/* <FilterAltOutlinedIcon sx={{ fontSize: '28px' }} /> */}
                                {/* </Box> */}
                              </Box>
                            </FixedTableCellForSearchField>
                            <TableCell
                              colSpan={4}
                              align={"left"}
                              sx={{
                                minWidth: 200,
                                padding: "5px 16px 5px 8px",
                                color: "#1976d2",
                                backgroundColor: "#FFFFFF",
                                borderRight: "2px solid #E2E6EA",
                                borderTopLeftRadius: "5px",
                                borderTopRightRadius: "5px",
                                borderRight: "1px solid #A0A0A0",
                                borderLeft: "1px solid #A0A0A0",
                                borderTop: "1px solid #A0A0A0",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{
                                      lineHeight: "22px",
                                      fontFamily: "BMSHumanity",
                                      fontWeight: 700,
                                      fontSize: 22,
                                      color: "#734392",
                                      padding: "5px 0px 0px 0px",
                                    }}
                                  >
                                    {"REMS Data"}
                                  </Typography>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{
                                      lineHeight: "22px",
                                      fontFamily: "BMSHumanity",
                                      fontSize: 16,
                                      color: "#595454",
                                    }}
                                  >
                                    {"Have questions about REMS Data?"}
                                  </Typography>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{
                                      lineHeight: "22px",
                                      fontFamily: "BMSHumanity",
                                      fontSize: 16,
                                      fontWeight: 700,
                                      color: "#595454",
                                    }}
                                  >
                                    {"+1 833-628-7367"}
                                  </Typography>
                                </Box>
                                <Box sx={{ padding: "0px 12px 0px 0px" }}>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{
                                      lineHeight: "48px",
                                      fontFamily: "BMSHumanity",
                                      fontWeight: 700,
                                      fontSize: 40,
                                      color: "#734392",
                                      textAlign: "right",
                                    }}
                                  >
                                    {tableHeadCounts?.remsTotalTaskCount > 9
                                      ? tableHeadCounts?.remsTotalTaskCount
                                      : `0${tableHeadCounts?.remsTotalTaskCount}`}
                                  </Typography>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{
                                      lineHeight: "19px",
                                      fontFamily: "BMSHumanity",
                                      fontSize: 16,
                                      color: "#734392",
                                    }}
                                  >
                                    Total Tasks
                                  </Typography>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell
                              colSpan={5}
                              align={"left"}
                              sx={{
                                minWidth: 200,
                                padding: "5px 16px 5px 8px",
                                color: "#1976d2",
                                backgroundColor: "#FFFFFF",
                                borderTopLeftRadius: "5px",
                                borderTopRightRadius: "5px",
                                marginLeft: "30px",
                                marginRight: "30px",
                                borderRight: "1px solid #A0A0A0",
                                borderLeft: "1px solid #A0A0A0",
                                borderTop: "1px solid #A0A0A0",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{
                                      lineHeight: "22px",
                                      fontFamily: "BMSHumanity",
                                      fontWeight: 700,
                                      fontSize: 22,
                                      color: "#005E82",
                                      padding: "5px 0px 0px 0px",
                                    }}
                                  >
                                    {"MyCAMZYOS Program Data "}
                                  </Typography>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{
                                      lineHeight: "22px",
                                      fontFamily: "BMSHumanity",
                                      fontSize: 16,
                                      color: "#595454",
                                    }}
                                  >
                                    {
                                      "Have questions about MyCAMZYOS Program Data?"
                                    }
                                  </Typography>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{
                                      lineHeight: "22px",
                                      fontFamily: "BMSHumanity",
                                      fontWeight: 700,
                                      fontSize: 16,
                                      color: "#595454",
                                    }}
                                  >
                                    {"+1 855-226-9967 "}
                                  </Typography>
                                </Box>
                                <Box sx={{ padding: "0px 12px 0px 0px" }}>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{
                                      lineHeight: "48px",
                                      fontFamily: "BMSHumanity",
                                      fontWeight: 700,
                                      fontSize: 40,
                                      color: "#005E82",
                                      textAlign: "right",
                                    }}
                                  >
                                    {tableHeadCounts?.camzyosTotalTaskCount > 9
                                      ? tableHeadCounts?.camzyosTotalTaskCount
                                      : `0${tableHeadCounts?.camzyosTotalTaskCount}`}
                                  </Typography>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{
                                      lineHeight: "19px",
                                      fontFamily: "BMSHumanity",
                                      fontSize: 16,
                                      color: "#005E82",
                                    }}
                                  >
                                    Total Tasks
                                  </Typography>
                                </Box>
                              </Box>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <FixedTableCell>
                              <TableSortLabel
                                active={sortConfig.key === "patient_name_dob"}
                                direction={
                                  sortConfig.key === "patient_name_dob"
                                    ? sortConfig.direction
                                    : "asc"
                                }
                                IconComponent={ArrowDropDownIcon}
                                sx={{
                                  "& .MuiTableSortLabel-icon": {
                                    //fontSize: '2rem', // Increase the icon size
                                    width: "24px", // Replace with your desired width
                                    height: "22px", // Replace with your desired height
                                    //color: 'red', // Change the icon color
                                    color: "#FFFFFF",
                                    marginLeft:'14px'
                                  },
                                  "&.Mui-active .MuiTableSortLabel-icon": {
                                    color: "#FFFFFF", // Set the color for active state
                                  },
                                }}
                                onClick={() => handleSort("patient_name_dob")}
                              >
                                <Box
                                  sx={{ display: "flex", flexDirection: "row" }}
                                >
                                  <Typography
                                    variant="subtitle1"
                                    sx={{
                                      lineHeight: "18px",
                                      fontFamily: "BMSHumanity",
                                      fontSize: 16,
                                      color: "#FFFFFF",
                                    }}
                                  >
                                    {"Patient Name and DOB"}
                                  </Typography>
                                </Box>
                              </TableSortLabel>
                            </FixedTableCell>
                            {patientTableData.remsPatientsHUBHeaders.map(
                              (itx, index) => (
                                <TableCell
                                  key={itx.id}
                                  align={itx.align}
                                  sx={{
                                    borderRight:
                                      itx.label === "Task(s)"
                                        ? "2px solid #E2E6EA"
                                        : null,
                                    minWidth: itx.minWidth,
                                    padding:
                                      index === 0
                                        ? "5px 0px 5px 10px"
                                        : "5px 0px 5px 5px",
                                    color: "#1976d2",
                                    backgroundColor: "#734392",
                                  }}
                                >
                                  <TableSortLabel
                                    active={sortConfig.key === itx.id}
                                    direction={
                                      sortConfig.key === itx.id
                                        ? sortConfig.direction
                                        : "asc"
                                    }
                                    IconComponent={ArrowDropDownIcon}
                                    sx={{
                                      "& .MuiTableSortLabel-icon": {
                                        //fontSize: '2rem', // Increase the icon size
                                        width: "24px", // Replace with your desired width
                                        height: "22px", // Replace with your desired height
                                        //color: 'red', // Change the icon color
                                        color: "#FFFFFF",
                                      },
                                      "&.Mui-active .MuiTableSortLabel-icon": {
                                        color: "#FFFFFF", // Set the color for active state
                                      },
                                      //   '& .MuiTableSortLabel-iconDirectionDesc': {
                                      //     color: 'red', // Change the icon color when sort direction is "desc"
                                      //   },
                                    }}
                                    onClick={() => handleSort(itx.id)}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <Typography
                                        variant="subtitle1"
                                        sx={{
                                          lineHeight: "18px",
                                          fontFamily: "BMSHumanity",
                                          fontSize: "16px",
                                          color: "#FFFFFF",
                                        }}
                                      >
                                        {itx.label}
                                      </Typography>
                                      {/* <div sx={{alignItems:'center', justifyContent:'center'}}><Arrow_Down /></div> */}
                                    </Box>
                                  </TableSortLabel>
                                </TableCell>
                              )
                            )}
                            {patientTableData.patient_support_hub_data.map(
                              (itx, index) => (
                                <TableCell
                                  key={itx.id}
                                  align={itx.align}
                                  sx={{
                                    minWidth: itx.minWidth,
                                    padding:
                                      index === 0
                                        ? "5px 0px 5px 10px"
                                        : "5px 0px 5px 5px",
                                    color: "#1976d2",
                                    backgroundColor: "#005E82",
                                  }}
                                >
                                  <TableSortLabel
                                    active={sortConfig.key === itx.id}
                                    direction={
                                      sortConfig.key === itx.id
                                        ? sortConfig.direction
                                        : "asc"
                                    }
                                    IconComponent={ArrowDropDownIcon}
                                    sx={{
                                      "& .MuiTableSortLabel-icon": {
                                        //fontSize: '2rem', // Increase the icon size
                                        width: "24px", // Replace with your desired width
                                        height: "22px", // Replace with your desired height
                                        //color: 'red', // Change the icon color
                                        color: "#FFFFFF",
                                      },
                                      "&.Mui-active .MuiTableSortLabel-icon": {
                                        color: "#FFFFFF", // Set the color for active state
                                      },
                                    }}
                                    onClick={() => handleSort(itx.id)}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <Typography
                                        variant="subtitle1"
                                        sx={{
                                          lineHeight: "18px",
                                          fontFamily: "BMSHumanity",
                                          fontSize: 16,
                                          color: "#FFFFFF",
                                        }}
                                      >
                                        {itx.label}
                                      </Typography>
                                      {/* <div sx={{alignItems:'center', justifyContent:'center'}}><Arrow_Down /></div> */}
                                    </Box>
                                  </TableSortLabel>
                                </TableCell>
                              )
                            )}
                          </TableRow>
                        </StickyTableHead>
                        <TableBody>
                          {visibleRows.map((row, index) => {
                            return (
                              <TableRow key={index}>
                                <FixedTableBodyCell align="left">
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "left",
                                    }}
                                  >
                                    <Typography
                                      variant="subtitle1"
                                      sx={{
                                        lineHeight: "20px",
                                        fontFamily: "BMSHumanity",
                                        fontSize: 16,
                                        color: "#000000",
                                      }}
                                    >
                                      {row.patient.first_name}
                                      {/* {", "}
                                      {row.patient.last_name} */}
                                    </Typography>
                                    <Typography
                                      variant="subtitle1"
                                      sx={{
                                        lineHeight: "20px",
                                        fontFamily: "BMSHumanity",
                                        fontSize: 16,
                                        color: "#000000",
                                      }}
                                    >
                                      {formatDate(row.date_of_birth)}
                                    </Typography>
                                  </Box>
                                </FixedTableBodyCell>
                                <TableCell
                                  align="left"
                                  sx={{
                                    borderBottom:
                                      index === visibleRows.length - 1
                                        ? "1px solid #A0A0A0"
                                        : "1px solid #D2B8E2",
                                    padding: "5px 0px 5px 10px",
                                  }}
                                >
                                  <Status type={row.rems_status.status} />
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{
                                    borderBottom:
                                      index === visibleRows.length - 1
                                        ? "1px solid #A0A0A0"
                                        : "1px solid #D2B8E2",
                                    padding: "5px 0px 5px 5px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <Typography
                                      variant="subtitle1"
                                      sx={{
                                        lineHeight: "20px",
                                        fontFamily: "BMSHumanity",
                                        fontSize: 16,
                                        color: "#595454",
                                        fontWeight: 400,
                                      }}
                                    >
                                      {row.psf_status?.last != "" &&
                                        formatDate(row.psf_status?.last)}
                                    </Typography>
                                    {row.psf_status?.last && (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                          width: "100%",
                                        }}
                                      >
                                        <Typography
                                          variant="subtitle1"
                                          sx={{
                                            lineHeight: "20px",
                                            fontFamily: "BMSHumanity",
                                            fontSize: 16,
                                            marginRight: "8px",
                                            color: "#595454",
                                            fontWeight: 400,
                                            width: "80%",
                                          }}
                                        >
                                          {row.psf_status.spname}
                                          {"\u00A0"}
                                          {row.psf_status.number ? (
                                            <TableTooltip
                                              visible={!true}
                                              title={
                                                <Box
                                                  sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    padding: 2,
                                                  }}
                                                >
                                                  <Typography
                                                    variant="body2"
                                                    sx={{
                                                      fontFamily: "BMSHumanity",
                                                      fontSize: 16,
                                                      lineHeight: "19.2px",
                                                      color: "#595454",
                                                    }}
                                                  >
                                                    {formatPhoneNumber(
                                                      row.psf_status.number
                                                    )}
                                                  </Typography>
                                                  <button
                                                    className="custom-button"
                                                    onClick={async () => {
                                                      try {
                                                        await navigator.clipboard.writeText(
                                                          formatPhoneNumber(
                                                            row.psf_status
                                                              .number
                                                          )
                                                        );
                                                        setShowToast(
                                                          !showToast
                                                        );
                                                      } catch (error) {
                                                        console.error(
                                                          "Failed to copy text: ",
                                                          error
                                                        );
                                                      }
                                                    }}
                                                  >
                                                    <Copy_Icon
                                                      sx={{
                                                        fontSize: "15px",
                                                        color: "#34619C",
                                                        marginLeft: "5px",
                                                      }}
                                                    />
                                                  </button>
                                                </Box>
                                              }
                                              maxWidth={250}
                                            >
                                              <Phone_Icon
                                                aria-label="Phone"
                                                tabIndex={0}
                                                sx={{
                                                  fontSize: "15px",
                                                  color: "#045CAC",
                                                  alignSelf: "flex-end",
                                                  marginTop: "5px",
                                                }}
                                              />
                                            </TableTooltip>
                                          ) : null}
                                        </Typography>
                                      </Box>
                                    )}
                                  </Box>
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{
                                    borderBottom:
                                      index === visibleRows.length - 1
                                        ? "1px solid #A0A0A0"
                                        : "1px solid #D2B8E2",
                                    padding: "5px 0px 5px 5px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    {/* <Typography variant="subtitle1" sx={{ lineHeight: '20px', fontFamily: 'BMSHumanity', fontSize:16, color:'#595454' }}>{formatDate(row.psf_status.next)} {' - '}</Typography> */}
                                    <Typography
                                      variant="subtitle1"
                                      sx={{
                                        lineHeight: "20px",
                                        fontFamily: "BMSHumanity",
                                        fontSize: 16,
                                        color: "#595454",
                                        fontWeight: 400,
                                      }}
                                    >
                                      {row.psf_status.nextTo}
                                    </Typography>
                                  </Box>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    borderBottom:
                                      index === visibleRows.length - 1
                                        ? "1px solid #A0A0A0"
                                        : "1px solid #D2B8E2",
                                    borderRight: "1px solid #E2E6EA",
                                  }}
                                >
                                  <div style={{ textAlign: "left" }}>
                                    {row.rems_tasks.tasks == 1 ? (
                                      <Circle
                                        number={row.rems_tasks.tasks}
                                        handleEvent={() =>
                                          onNumberClick(
                                            "Rems",
                                            row.rems_tasks.tasks,
                                            row
                                          )
                                        }
                                        type={
                                          row.rems_tasks.tasks === 0
                                            ? ""
                                            : "REMS"
                                        }
                                      />
                                    ) : (
                                      <p style={{ marginLeft: "7px" }}>--</p>
                                    )}
                                  </div>
                                  {/* </TableTooltip> */}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{
                                    borderBottom:
                                      index === visibleRows.length - 1
                                        ? "1px solid #A0A0A0"
                                        : "1px solid #89C4DB",
                                    padding: "5px 0px 5px 10px",
                                  }}
                                >
                                  {/* <Status type={row.hub_status.camzyos_status }/> */}

                                  {row.hub_status.camzyos_status?.toLowerCase() ===
                                  "incomplete" ? (
                                    <TableTooltip
                                      visible={
                                        row.hub_status?.camzyos_status?.toLowerCase() !==
                                        "incomplete"
                                          ? true
                                          : false
                                      }
                                      title={
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            padding: 2,
                                          }}
                                        >
                                          <Typography
                                            variant="body2"
                                            sx={{
                                              fontFamily: "BMSHumanity",
                                              fontSize: 16,
                                            }}
                                          >
                                            Enrollment form is missing info.
                                            Complete within CoverMyMeds Portal.
                                          </Typography>
                                        </Box>
                                      }
                                      maxWidth={250}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                          backgroundColor: "#FEFFBF",
                                          width: "100px",
                                          justifyContent: "center",
                                          padding: "0px 10px 0px 0px",
                                        }}
                                      >
                                        <PartiallyEnrolled width={"100%"} />
                                        <Typography
                                          variant="body2"
                                          sx={{
                                            lineHeight: "normal",
                                            fontFamily: "BMSHumanity",
                                            fontSize: 16,
                                            color: "#C74902",
                                            padding: "0px",
                                          }}
                                        >
                                          {"Incomplete"}
                                        </Typography>
                                      </Box>
                                    </TableTooltip>
                                  ) : (
                                    <Status
                                      type={row.hub_status.camzyos_status}
                                    />
                                  )}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{
                                    borderBottom:
                                      index === visibleRows.length - 1
                                        ? "1px solid #A0A0A0"
                                        : "1px solid #89C4DB",
                                    padding: "5px 0px 5px 5px",
                                  }}
                                >
                                  <Status type={row.hub_status.access_status} />
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{
                                    borderBottom:
                                      index === visibleRows.length - 1
                                        ? "1px solid #A0A0A0"
                                        : "1px solid #89C4DB",
                                    padding: "5px 0px 5px 5px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      lineHeight: "normal",
                                    }}
                                  >
                                    <Typography
                                      variant="subtitle1"
                                      sx={{
                                        fontFamily: "BMSHumanity",
                                        fontSize: 16,
                                        lineHeight: "20px",
                                        color: "#595454",
                                      }}
                                    >
                                      {formatDate(row.hub_status?.date)}
                                    </Typography>
                                  </Box>
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{
                                    borderBottom:
                                      index === visibleRows.length - 1
                                        ? "1px solid #A0A0A0"
                                        : "1px solid #89C4DB",
                                    padding: "5px 0px 5px 5px",
                                  }}
                                >
                                  <TableTooltip
                                    visible={
                                      row.dispense.type_of_dispense?.toLowerCase() !==
                                      "emergency fill"
                                    }
                                    title={
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          padding: 2,
                                        }}
                                      >
                                        <Typography
                                          variant="body2"
                                          sx={{
                                            fontFamily: "BMSHumanity",
                                            fontSize: 16,
                                            lineHeight: "19.2px",
                                            color: "#595454",
                                          }}
                                        >
                                          {row.dispense.tooltip_text}
                                        </Typography>
                                      </Box>
                                    }
                                    maxWidth={650}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        lineHeight: "normal",
                                      }}
                                    >
                                      <Typography
                                        variant="subtitle1"
                                        sx={{
                                          lineHeight: "20px",
                                          fontFamily: "BMSHumanity",
                                          fontSize: 16,
                                          color: "#595454",
                                        }}
                                      >
                                        {row.dispense.type_of_dispense}
                                        {row.dispense.type_of_dispense?.toLowerCase() ===
                                        "emergency fill" ? (
                                          <InfoIcon
                                            sx={{
                                              position: "relative",
                                              top: "3px",
                                              fontSize: "18px",
                                              color: "#595454",
                                            }}
                                          />
                                        ) : null}
                                      </Typography>
                                    </Box>
                                  </TableTooltip>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    borderBottom:
                                      index === visibleRows.length - 1
                                        ? "1px solid #A0A0A0"
                                        : "1px solid #89C4DB",
                                    borderRight: "1px solid #A0A0A0",
                                  }}
                                >
                                  <div style={{ textAlign: "left" }}>
                                    {row.phub_tasks.tasks > 0 ? (
                                      <Circle
                                        number={row.phub_tasks.tasks}
                                        type={
                                          row.phub_tasks.tasks === 0
                                            ? ""
                                            : "PHUB"
                                        }
                                        handleEvent={() =>
                                          onNumberClick(
                                            "Pdata",
                                            row.phub_tasks.tasks,
                                            row
                                          )
                                        }
                                      />
                                    ) : (
                                      <p style={{ marginLeft: "7px" }}>--</p>
                                    )}
                                  </div>
                                  {/* </TableTooltip> */}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </ScrollableTableContainer>
                  </div>
                </div>
              </Paper>
            </Box>
            <Box style={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="body2"
                style={{
                  color: "#595454",
                  fontSize: 18,
                  fontFamily: "BMSHumanity-Italic",
                  lineHeight: "24px",
                }}
                gutterBottom
              >
                Above reflects data from consented patients only
              </Typography>
            </Box>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="body2"
                  style={{
                    marginBottom: "0px",
                    color: "#595454",
                    fontFamily: "BMSHumanity",
                    fontSize: 16,
                    fontWeight: 600,
                  }}
                  gutterBottom
                >
                  {startIndex + 1}-{endIndex} of {filteredData.length}
                </Typography>
              </div>
              <Pagination
                //rowsPerPageOptions={[5, 10, 25]}
                component="div"
                //count={filteredData.length/10}
                count={Math.ceil(filteredData.length / rowsPerPage)}
                //rowsPerPage={rowsPerPage}
                //pageSize={rowsPerPage}
                page={page}
                defaultPage={1}
                onChange={handleChangePage}
                // onPageChange={handleChangePage}
                //onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                  "& .MuiPaginationItem-root": {
                    color: "#BE2BBB", // Change the color here
                    fontSize: 16,
                    fontFamily: "BMSHumanity",
                    fontWeight: 500,
                    lineHeight: "16px",
                  },
                  "& .Mui-selected": {
                    borderRadius: "1px",
                    borderColor: "#8338EC",
                    boxShadow: "0 0 0 2px #8338EC",
                    //backgroundColor: 'grey', // Change the highlight color here
                    color: "#595454", // Change the text color here
                    fontSize: 16,
                    fontFamily: "BMSHumanity",
                    fontWeight: 500,
                    lineHeight: "16px",
                    // '&:hover': {
                    //   backgroundColor: '#8338EC', // Change the highlight color on hover here
                    // },
                  },
                }}
                renderItem={(item) => (
                  <PaginationItem
                    slots={{
                      previous: () => (
                        <Typography
                          variant="label"
                          sx={{
                            lineHeight: "16px",
                            color: "#BE2BBB",
                            fontFamily: "BMSHumanity",
                            fontSize: 16,
                            fontWeight: 600,
                          }}
                        >
                          {" "}
                          {"< Previous"}
                        </Typography>
                      ),
                      next: () => (
                        <Typography
                          variant="label"
                          sx={{
                            lineHeight: "16px",
                            color: "#BE2BBB",
                            padding: "0 12px",
                            fontFamily: "BMSHumanity",
                            fontSize: 16,
                            fontWeight: 600,
                          }}
                        >
                          {"Next >"}
                        </Typography>
                      ),
                    }}
                    {...item}
                  />
                )}
                components={{
                  FirstPage: IconButton,
                  LastPage: IconButton,
                  NextPage: IconButton,
                  PreviousPage: IconButton,
                }}
              />
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                height: "43vh",
                alignContent: "center",
                justifyItems: "center",
                alignSelf: "center",
              }}
            >
              <Typography
                sx={{
                  alignSelf: "center",
                  fontSize: 18,
                  color: "#595454",
                  lineHeight: "normal",
                  fontFamily: "BMSHumanity",
                  fontWeight: 700,
                }}
              >
                There are no Patients associated with your account.
              </Typography>
            </div>
          </>
        )
      ) : (
        <Loader />
      )}
      {showToast && (
        <Snackbar
          open={showToast}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          autoHideDuration={3000}
          onClose={() => setShowToast(!showToast)}
          message="Phone number copied to clipboard"
        />
      )}
    </>
  );
};

export default PatientTable;
