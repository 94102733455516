import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Link,
  Typography,
  styled,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Grid } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { ReactComponent as AccordionUpIcon } from "../assets/images/accordian_up.svg";
import { ReactComponent as AccordionDownIcon } from "../assets/images/accordian_down.svg";
import { ReactComponent as ChatPurpleIcon } from "../assets/images/chat-purple.svg";
import { ReactComponent as ChatBGIcon } from "../assets/images/chatBg.svg";

import DATA from "../assets/json/help.json";
import LeavingPopup from "../components/LeavingPopup";
const ScrollableBox = styled(Box)({
  flex: 1,
  position: "fixed",
  overflowY: "scroll",
  overflowX: "hidden",
  backgroundColor: "#f2f2f2",
  height: "100%",
  width: "100%",
  minHeight: "calc(100vh - 64px)",
  minWidth: 650,
  "&::-webkit-scrollbar": {
    width: "0",
  },
});
const TypographyHeader = styled(Typography)(({ theme }) => ({
  fontFamily: "BMSHumanity",
  fontSize: "24px",
  fontWeight: 700,
  textAlign: "left",
  lineHeight: "32px",
  color: "#595454",
}));
const CardTitleHeader = styled(Typography)(({ theme }) => ({
  fontFamily: "BMSHumanity",
  fontSize: "20px",
  fontWeight: 700,
  lineHeight: "32px",
  color: "#595454",
}));

const AccordianHeader = styled(Typography)(({ theme }) => ({
  fontFamily: "BMSHumanity",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "32px",
  color: "#595454",
}));
const AccordianTypeDetails = styled(Typography)(({ theme }) => ({
  fontFamily: "BMSHumanity",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "20px",
  color: "#595454",
}));

const Help = () => {
  const [accordionOpen, setAccordionOpen] = useState(
    "What information is provided in the CAMZYOS dashboard?"
  );
  const [chatOpen, isChatOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  useEffect(() => {
    console.log("Loads first");
    setTimeout(() => {
      window["loadChat"]();
    }, 500);

  }, []);
  useLayoutEffect(() => {
    document.body.style.backgroundColor = "#f2f2f2";
  });

  const handleChatIcon = () => {
    window._satellite.track("cta-chat", { name: "Chat_Window",location: "NA" });
    if (!chatOpen) {
      isChatOpen(!chatOpen);
      window["initConverse"]();
    } else {
      isChatOpen(!chatOpen);
      window["CloseChat"]();
    }
  };
  return (
    <ScrollableBox
      sx={{
        flex: 1,
        position: "relative",
        overflowY: "scroll",
        overflowX: "hidden",
        backgroundColor: "#f2f2f2",
        height: "100%",
        width: "100%",
        minHeight: "calc(100vh - 64px)",
        minWidth: 650,
      }}
    >
      <div
        style={{
          flex: 1,
          paddingLeft: "41px",
          paddingRight: "41px",
        }}
      >
        <div style={{ padding: 16 }}>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="medium" />}
          >
            <Link underline="hover" color="#BE2BBB" href="/">
              Dashboard
            </Link>
            <Typography
              color="#000000"
              fontFamily={"BMSHumanity"}
              fontSize="16px"
              lineHeight="32px"
            >
              Help
            </Typography>
          </Breadcrumbs>
        </div>
        <div style={{ padding: 16, marginTop: "15px" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TypographyHeader component="h1">
                {" "}
                Help - Frequently Asked Questions
              </TypographyHeader>
              <Typography
                style={{
                  fontFamily: "BMSHumanity",
                  fontSize: "16px",
                  fontWeight: 400,
                  textAlign: "left",
                  color: "#000000",
                }}
              >
                We have shared some of the most frequently asked questions to
                help you out!
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div style={{ padding: 16 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Box
                style={{ border: "1px solid #A0A0A0", backgroundColor: "#fff" }}
                sx={{
                  lineHeight: { md: "25px", lg: "115px" },
                  border: {
                    xs: "1px solid #A0A0A0",
                    md: "1px solid #A0A0A0",
                    lg: "1px solid #A0A0A0",
                  },
                }}
              >
                {DATA?.grid1.map((item, index) => {
                  return (
                    <Accordion
                      expanded={
                        accordionOpen != "" && item.title == accordionOpen
                          ? true
                          : false
                      }
                      style={{ margin: "0px !important" }}
                      disableGutters
                    >
                      <AccordionSummary
                        sx={{
                          backgroundColor:
                            item.title != "" && item.title == accordionOpen
                              ? "#BE2BBB"
                              : "#fff",
                          marginBottom: 0,
                          cursor: "unset !important",
                          "& .MuiAccordionSummary-content": {
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                          },
                        }}
                        //   expandIcon={<AccordionUpIcon />}
                        aria-controls="panel1-content"
                        //id="panel1-header"
                        id={`panel1-header-${index}`}
                      >
                        <AccordianHeader
                          style={{
                            color:
                              item.title == accordionOpen ? "#fff" : "#595454",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                          sx={{
                            lineHeight: { xs: "16px", md: "16px", lg: "20px" },
                          }}
                        >
                          {item.title}
                        </AccordianHeader>
                        <button
                          onClick={() =>{
                            window._satellite.track("cta-faq", { name: item.shortName,description: item.description });
                            setAccordionOpen(
                              item.title === accordionOpen ? "" : item.title
                            )
                          }}
                          aria-expanded={accordionOpen === item.title}
                          aria-controls={`panel-content-${index}`}
                          id={`panel-button-${index}`}
                          style={{
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                          }}
                        >
                          {accordionOpen === item.title ? (
                            <AccordionUpIcon />
                          ) : (
                            <AccordionDownIcon />
                          )}
                        </button>
                      </AccordionSummary>
                      <AccordionDetails>
                        {item.answer ===
                        "The CAMZYOS dashboard can be accessed with the same username and password you use for the REMS portal. The link to the dashboard is CAMZYOSdashboard.com" ? (
                          <p
                            style={{
                              fontFamily: "BMSHumanity",
                              fontSize: "16px",
                              fontWeight: 400,
                              lineHeight: "20px",
                              color: "#595454",
                            }}
                          >
                            The CAMZYOS dashboard can be accessed with the same
                            username and password you use for the REMS portal.
                            The link to the dashboard is{" "}
                            <a href="#" style={{ color: "#BE2BBB" }}>
                              CAMZYOSdashboard.com
                            </a>
                          </p>
                        ) : (
                          <AccordianTypeDetails>
                            {item.answer}
                          </AccordianTypeDetails>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                style={{ border: "1px solid #A0A0A0", backgroundColor: "#fff" }}
                sx={{
                  // backgroundColor: { xs: "green", md: "red", lg: "#fff" },
                  // lineHeight: { xs: "50px", md: "40px", lg: "40px" },
                  border: {
                    xs: "1px solid #A0A0A0",
                    md: "1px solid #A0A0A0",
                    lg: "1px solid #A0A0A0",
                  },
                }}
              >
                {DATA?.grid2.map((item, index) => {
                  return (
                    <Accordion
                      key={index}
                      expanded={
                        accordionOpen != "" && item.title == accordionOpen
                          ? true
                          : false
                      }
                      style={{ border: "1px 1px 1px 0px" }}
                      disableGutters
                    >
                      <AccordionSummary
                        sx={{
                          backgroundColor:
                            item.title != "" && item.title == accordionOpen
                              ? "#BE2BBB"
                              : "#fff",
                          marginBottom: 0,
                          cursor: "unset !important",
                          "& .MuiAccordionSummary-content": {
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                          },
                          position: item.title != "" ? "relative" : "static",
                        }}
                        //   expandIcon={<AccordionUpIcon />}
                        aria-controls="panel2-content"
                        //id="panel1-header"
                        id={`panel2-header-${index}`}
                      >
                        <AccordianHeader
                          style={{
                            color:
                              item.title == accordionOpen ? "#fff" : "#595454",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                          sx={{
                            lineHeight: {
                              xs: "16px",
                              md: "17.5px",
                              lg: "17.5px",
                            },
                          }}
                        >
                          {item.title}
                        </AccordianHeader>
                        <button
                          onClick={() =>{
                            window._satellite.track("cta-faq", { name: item.shortName,description: item.description });
                            setAccordionOpen(
                              item.title === accordionOpen ? "" : item.title
                            )
                          }}
                          aria-expanded={accordionOpen === item.title}
                          aria-controls={`panel2-content-${index}`}
                          id={`panel2-button-${index}`}
                          style={{
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                          }}
                        >
                          {item.title != "" &&
                            (accordionOpen === item.title ? (
                              <AccordionUpIcon />
                            ) : (
                              <AccordionDownIcon />
                            ))}
                        </button>
                      </AccordionSummary>
                      <AccordionDetails id={`panel2-content-${index}`}>
                        <AccordianTypeDetails>
                          {item.answer}
                        </AccordianTypeDetails>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </Box>
            </Grid>
          </Grid>
        </div>
        <div style={{ padding: 16 }}>
          <div
            style={{
              backgroundColor: "#FFF3E0",
              width: "97%",
              borderRadius: "3px 0px 0px 0px",
              border: "1px solid #A0A0A0",
              padding: "24px",
            }}
          >
            <CardTitleHeader component="h1">
              Still have additional questions?
            </CardTitleHeader>
            <Typography
              sx={{
                fontFamily: "BMSHumanity",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "18px",
                textAlign: "left",
                color: "#595454",
              }}
            >
              Please contact your Access and Reimbursement Manager if you have
              any questions or need assistance.
            </Typography>
          </div>
        </div>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            position: "fixed",
            bottom: "40px",
            right: "40px",
            padding: "4px",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "row" }}
            onClick={handleChatIcon}
          >
            <ChatBGIcon />
            <ChatPurpleIcon
              height={50}
              width={50}
              style={{ marginTop: "8px" }}
            />
          </div>
        </Box>
      </div>
      {showPopup && (
        <LeavingPopup
          open={showPopup}
          setOpen={setShowPopup}
          title={"Camzyos"}
          description={
            "Thank you for your submission. Your BMS ARM will reach out to you shortly"
          }
          showProceed={false}
        />
      )}
    </ScrollableBox>
  );
};

export default Help;
