import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Box, Grid, Modal } from "@mui/material";
import "./modal.css";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: '50%',
  bgcolor: "background.paper",
  border: "2px solid #fff",
  boxShadow: 24,
  // p: 4,
};

export default function ModalApp({ open, setOpen,count,obj,messagesList}) {

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false)
    }
  }
  const handleClick = (event,item) => {
    if (event.target.tagName === 'A') {
      window._satellite.track("cta-portal-link", { name: "REMS Portal",location: "Tasks-Popup" });
      event.preventDefault();
      // Show your popup logic here
      if(item.link !=''){
        setOpen(false);
        window.open(item.link, "_blank");
      }
    }
  };
 
  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={handleClose}
        onBackdropClick={false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

      <div>
         <Box sx={style}>
          <div style={{borderBottom: "1px solid #857F7F",padding:'24px'}}>
          <h2
            style={{
              fontFamily: "BMSHumanity",
              fontSize: "32px",
              fontWeight: 400,
              lineHeight: "38.4px",
              textAlign: "left",
              color: "#734392",
              margin:'0px'
            }}
          >
            REMS Data - Tasks
          </h2>
          <h2
            style={{
              fontFamily: "Ubuntu",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "20px",
              textAlign: "left",
              color: "#595454",
              margin:'0px'
            }}
          >
            Patient Name: {obj.patient.first_name} {obj.patient.last_name}
          </h2>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
              '&:hover': {
               // backgroundColor: 'red', // Change this to the desired background color on hover
              },
            }}
          >
            <CloseIcon />
          </IconButton>
          </div>
         
          <div style={{padding:'24px'}}>
           {messagesList.filter((item)=> item.title?.toLowerCase() == obj.rems_status.status?.toLowerCase()).map((item)=>{
 return (<Grid container spacing={3} key={item}>
 <Grid item xs={12} sm={12}>
   {item.isTitleNeeded && (<div
     style={{
       padding: 4,
       fontFamily: "BMSHumanity",
       fontSize: "16px",
       fontWeight: 700,
       lineHeight: "28px",
       textAlign: "left",
     }}
   >
     {item.title}{" "}
   </div>)}
     <li  style={{
       padding: 4,
       fontFamily: "BMSHumanity",
       fontSize: "16px",
       fontWeight: 400,
       lineHeight: "28px",
       textAlign: "left",
       color:'#595454',
       listStyleType: 'none'
     }}
     onClick={(event)=>handleClick(event,item)}
     dangerouslySetInnerHTML={{__html:item.message}}
     >
      
     </li>
 </Grid>
</Grid>)
           })}
          </div>
        </Box>
        </div>
      </Modal>
    </React.Fragment>
  );
}
